import { render, staticRenderFns } from "./Hzzp.vue?vue&type=template&id=2c266080&scoped=true&"
import script from "./Hzzp.vue?vue&type=script&lang=js&"
export * from "./Hzzp.vue?vue&type=script&lang=js&"
import style0 from "../style.css?vue&type=style&index=0&id=2c266080&prod&scoped=true&lang=css&"
import style1 from "./Hzzp.vue?vue&type=style&index=1&id=2c266080&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2c266080",
  null
  
)

export default component.exports