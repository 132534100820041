<template>
    <div class="zt-page-content">
        <div class="flex flex-v" style="min-width:920px;height:100%;overflow:hidden;">
            <div style="margin-bottom:0;" class="zt-block flex">
                <div class="flex-1"></div>
                <!-- 产品名、公司名、型号 -->
                <div class="input-block flex">
                    <el-input v-model="searchValue" placeholder="产品名、公司名、型号" 
                        prefix-icon="el-icon-search"></el-input>
                    <el-button @click="search">搜索</el-button>
                </div>
            </div>
            <div class="hzzp-list flex-1" ref="list" style="overflow-y:auto;">
                <div v-if="items && items.length>0">
                    <div v-for="(item,idx) in items" :key="idx" class="list-item">
                        <Commodity v-if="materials && colors" :item="item" :materials="materials" :colors="colors"></Commodity>
                    </div>
                </div>
            </div>
            <div v-if="pageShow" class="pagination-wrap">
                <el-pagination background layout="prev, pager, next" @current-change="currentChange"
                :current-page.sync="queryOpt.page" :page-size="queryOpt.pagesize"
                :total="total"></el-pagination>
            </div>
            
        </div>
    </div>
</template>

<script>
import _ from 'lodash';
import { facPercentList } from '@/service/factory';
import { materialList, colorList } from "@/service/item";
import Commodity from '@/components/factory/Commodity.vue'

export default {
    components: {
        Commodity
    },
    data() {
        return {
            queryOpt: {
                fname:'',
                pagesize: 5,
                page: 1,
            },
            total:5,
            searchValue:"",
            pageShow:false, // 强制刷新分页组件
            items:[],
            materials:null,
            colors:null,
        }
    },
    // watch: {
    //     queryOpt: {
    //         handler() {
    //             this.debounceRefreshItems();
    //         },
    //         deep: true
    //     },
    // },
    async created() {
        this.debounceRefreshItems = _.debounce(this.refreshItems, 500);
        this.materials = await materialList({hasCommon:true});
        this.colors = await colorList({hasCommon:true});
    },
    mounted() {
        if (this.$route.query.page && this.$route.query.page>1) {
            if (this.$route.query.fname) {
                this.queryOpt.fname = this.$route.query.fname;
                this.searchValue = this.queryOpt.fname;
            }
            this.queryOpt.page=parseInt(this.$route.query.page);
            this.refreshItems();
        } else {
            if (this.$route.query.fname) {
                this.queryOpt.fname = this.$route.query.fname;
                this.searchValue = this.queryOpt.fname;
            }
            this.refreshItems();
        }
        this.$nextTick(()=>{
            this.pageShow = true;
        })
    },
    methods: {
        search() {
            if (this.queryOpt.page != this.$route.query.page) {
                this.$router.push({
                    query:{ page:1, fname: this.searchValue}
                });
            }
            this.pageShow = false;
            this.queryOpt.page = 1;
            this.queryOpt.fname = this.searchValue;
            this.refreshItems();
            this.$nextTick(()=>{
                this.pageShow = true;
            })
        },
        currentChange(e) {
            if (this.queryOpt.fname) {
                this.searchValue = this.queryOpt.fname;
                this.$router.push({
                    query:{ page:e, fname: this.queryOpt.fname}
                });
            } else {
                this.$router.push({
                    query:{ page:e }
                });
            }
            this.refreshItems();
        },
        refreshItems(){console.log('facPercentList');
            this.items = [];
            const _loading = this.$loading({
                target:this.$refs.list,
                lock: true,
                text: "拼命加载中....",
            });
            facPercentList(this.queryOpt).then(rst=>{
                this.total=rst.total;
                this.items = rst.list;
                console.log(this.items);
                _loading.close();
            }).catch(err => {
                _loading.close();
                console.log(err);
                this.$message.error(err.message);
            });
        },
    }
}
</script>

<style scoped src="../style.css"></style>
<style scoped lang="less">
.pagination-wrap {
    padding:12px 55px;
    text-align:center;
}
.zt-page-content {
    .input-block {
        padding: 10px 55px;
        text-align: right;
        flex-wrap: nowrap;
        .el-input {
            max-width:200px;
        }
        .el-button {
            width: 76px;
            left: -4px;
            position: relative;
            z-index: 2;
        }
    }
}
</style>